<template>
	<div class="sycm-page">
		<div class="page-section page-survey">
			<div class="survey-main">
				<div class="survey-top">
					<div class="name">{{$t('sycm.home.overview')}}</div>
					<div class="barbox">
						<span class="time">{{ $t('sycm.common.updateTime') }}：{{ parseInt(Date.now()/1000) | parseTime()}}</span>
						<router-link class="next" :to="{ name: 'SycmIpollIndex' }">{{ $t('sycm.actions.realTimeLive') }}<i class="el-icon-arrow-right"></i>
						</router-link>
					</div>
				</div>
				<div class="survey-layout">
					<div class="layout-left">
						<div class="survey-pay">
							<div class="survey-item">
								<div class="item-icon payment-icon">
									<svg-icon icon-class="pay_money"></svg-icon>
								</div>
								<div class="item-main">
									<div class="name">{{ $t('sycm.common.payMoney') + '('+$t('sycm.common.rmb') + ')' }}</div>
									<div class="number">{{ payData.today_order_price | priceFilter()}}</div>
								</div>
							</div>
							<div class="survey-data">
								<p>{{$t('sycm.home.tradeRank')}}<span>{{payData.industry_ranking || '100+'}}</span></p>
								<p>{{$t('sycm.home.wirelessMix')}}<span>{{payData.wireless_proportion}}%</span></p>
								<p>{{$t('sycm.home.yesterdayAllDay')}}<span>{{payData.yesterday_num}}</span></p>
							</div>
						</div>
						<div class="survey-chart">
							<pay-chart id="pay-money" v-if="payData.list" :chart-data="payData.list" height="100%" width="100%" />
						</div>
					</div>
					<div class="layout-right">
						<ul>
							<li>
								<div class="survey-item">
									<div class="item-icon visitor-icon"><svg-icon icon-class="visitor"></svg-icon></div>
									<div class="item-main">
										<div class="name">{{$t('sycm.common.visitor')}}</div>
										<div class="number">{{statistics.visitors?statistics.visitors.today_num:'0'}}</div>
									</div>
								</div>
								<div class="survey-data">
									<p>{{$t('sycm.home.wirelessMix')}}<span>{{statistics.visitors?statistics.visitors.mobile_percent:'0.00'}}%</span></p>
									<p class="rank">{{$t('sycm.home.rank')}}<span>100+</span></p>
									<p>{{$t('sycm.home.yesterdayAllDay')}}<span>{{statistics.visitors?statistics.visitors.yesterday_num:'0'}}</span></p>
								</div>
							</li>
							<li>
								<div class="survey-item">
									<div class="item-icon buyers-icon"><svg-icon icon-class="buyers"></svg-icon></div>
									<div class="item-main">
										<div class="name">{{$t('sycm.common.payBuyers')}}</div>
										<div class="number">{{statistics.buyers?statistics.buyers.today_num:'0'}}</div>
									</div>
								</div>
								<div class="survey-data">
									<p>{{$t('sycm.home.wirelessMix')}}<span>{{statistics.buyers?statistics.buyers.mobile_percent:'0.00'}}%</span></p>
									<p class="rank">{{$t('sycm.home.rank')}}<span>100+</span></p>
									<p>{{$t('sycm.home.yesterdayAllDay')}}<span>{{statistics.buyers?statistics.buyers.yesterday_num:'0'}}</span></p>
								</div>
							</li>
							<li>
								<div class="survey-item">
									<div class="item-icon views-icon">
										<svg-icon icon-class="views"></svg-icon>
									</div>
									<div class="item-main">
										<div class="name">{{$t('sycm.common.views')}}</div>
										<div class="number">{{statistics.views?statistics.views.today_num:'0'}}</div>
									</div>
								</div>
								<div class="survey-data">
									<p>{{$t('sycm.home.wirelessMix')}}<span>{{statistics.views?statistics.views.mobile_percent:'0.00'}}%</span></p>
									<p class="rank">{{$t('sycm.home.rank')}}<span>100+</span></p>
									<p>{{$t('sycm.home.yesterdayAllDay')}}<span>{{statistics.views?statistics.views.yesterday_num:'0'}}</span></p>
								</div>
							</li>
							<li>
								<div class="survey-item">
									<div class="item-icon suborder-icon">
										<svg-icon icon-class="sub_order"></svg-icon>
									</div>
									<div class="item-main">
										<div class="name">{{$t('sycm.common.paySubOrder')}}</div>
										<div class="number">{{statistics.sub_order?statistics.sub_order.today_num:'0'}}</div>
									</div>
								</div>
								<div class="survey-data">
									<p>{{$t('sycm.home.wirelessMix')}}<span>{{statistics.sub_order?statistics.sub_order.mobile_percent:'0.00'}}%</span></p>
									<p class="rank">{{$t('sycm.home.rank')}}<span>100+</span></p>
									<p>{{$t('sycm.home.yesterdayAllDay')}}<span>{{statistics.sub_order?statistics.sub_order.yesterday_num:'0'}}</span></p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="survey-aside">
				<div class="aside-user">
					<img :src="storeInfo.store_logo ? storeInfo.store_logo : $imageErrorLogo" class="pic"
						@error="imageLoadError" alt="">
					<div class="name"><span>{{ storeInfo.store_name }}</span></div>
				</div>
				<div class="aside-action">
					<el-button size="small" @click="goShop()" round><i class="el-icon-s-shop"></i>{{ $t('seller.home.into') }}</el-button>
					<!-- <el-button size="small" round>{{ $t('seller.home.alipay') }}</el-button> -->
				</div>
				<div class="aside-main">
					<!-- <div class="item">
						<div class="item-label">{{ $t('seller.home.credit') }}</div>
						<div class="item-main"><span class="item-text">{{ $t('seller.home.points') }}</span></div>
					</div>
					<div class="item">
						<div class="item-label">{{ $t('seller.home.score') }}</div>
						<div class="item-main">
							<div class="item-cell">
								<div class="item-cell-title">{{ $t('seller.home.describe') }}</div>
								<div class="item-cell-value">0.0</div>
								<span class="item-cell-text">-</span>
							</div>
							<div class="item-cell">
								<div class="item-cell-title">{{ $t('seller.home.service') }}</div>
								<div class="item-cell-value">0.0</div>
								<span class="item-cell-text">-</span>
							</div>
							<div class="item-cell">
								<div class="item-cell-title">{{ $t('seller.home.logistics') }}</div>
								<div class="item-cell-value">0.0</div>
								<span class="item-cell-text">-</span>
							</div>
						</div>
					</div> -->
					<div class="item">
						<div class="item-label">{{ $t('seller.home.deposit') }}</div>
						<div class="item-main">
							<el-tag type="success" effect="dark" size="small">{{ $t('seller.home.isPay') }}</el-tag>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="page-section">
			<div class="section-head">
				<div class="title">
					<!-- <svg-icon icon-class="refund"></svg-icon> -->
					<span>{{$t('sycm.home.refundPanel')}}</span>
				</div>
			</div>
			<div class="page-refund">
				<div class="item">
					<div class="item-main">
						<div class="name">
							<span>{{$t('sycm.home.refundRate')}}</span>
							<el-popover
								placement="top-start"
								width="477"
								trigger="hover"
								popper-class="sycm-popover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-head">{{$t('sycm.popover.refundRate1')}}</div>
								<div class="popover-main">
									<el-row>
										<el-col :span="12">
											<div class="popover-formula">
												<div class="formula-title">{{$t('sycm.popover.formula')}}</div>
												<div class="formula-item">
													<div class="item-label">{{$t('sycm.home.refundRate')}} = </div>
													<div class="item-block">
														<div class="text">{{$t('sycm.home.refundNumber')}}</div>
														<div class="fit"></div>
														<div class="text">{{$t('sycm.common.paySubOrder')}}</div>
													</div>
												</div>
											</div>
										</el-col>
										<el-col :span="12">
											<div class="popover-refund">
												<div class="refund-item">
													<div class="item-title red"><i class="el-icon-top"></i>{{$t('sycm.popover.refundRate2')}}</div>
													<div class="item-main">{{$t('sycm.popover.refundRate3')}}</div>
												</div>
												<div class="refund-item">
													<div class="item-title green"><i class="el-icon-bottom"></i>{{$t('sycm.popover.refundRate4')}}</div>
													<div class="item-main">{{$t('sycm.popover.refundRate5')}}</div>
												</div>
											</div>
										</el-col>
									</el-row>
								</div>
							</el-popover>
						</div>
						<div class="number">{{refundRate.refund_rate}}%</div>
					</div>
					<ul class="item-box">
						<li>
							<div class="cell-label">{{$t('sycm.home.dayBefore')}}</div>
							<div class="cell-value">
								<span>{{refundRate.yesterday_refund_rate || '0.00'}}%</span>
								<span>-</span>
							</div>
						</li>
						<li>
							<div class="cell-label">{{$t('sycm.home.weekBefore')}}</div>
							<div class="cell-value">
								<span>{{refundRate.last_week_refund_rate || '0.00'}}%</span>
								<span>-</span>
							</div>
						</li>
					</ul>
					<div class="item-chart">
						<area-chart id="refund-rate" v-if="refundRate.list" :series-data="refundRate.list" :series-name="$t('sycm.home.refundRate')" formatter-y="{value} %" max-axis="100" height="100%" width="100%" />
					</div>
				</div>
				<div class="item">
					<div class="item-main">
						<div class="name">
							<span>{{$t('sycm.home.refundPrice')}}</span>
						</div>
						<div class="number">{{refundMoney.today_price | priceFilter()}}</div>
					</div>
					<ul class="item-box">
						<li>
							<div class="cell-label">{{$t('sycm.home.dayBefore')}}</div>
							<div class="cell-value">{{refundMoney.yesterday_price | priceFilter()}}</div>
						</li>
						<li>
							<div class="cell-label">{{$t('sycm.home.weekBefore')}}</div>
							<div class="cell-value">{{refundMoney.last_week_day_price | priceFilter()}}</div>
						</li>
					</ul>
					<div class="item-chart">
						<area-chart id="refund-money" v-if="refundMoney.list" :series-data="refundMoney.list" :series-name="$t('sycm.home.refundPrice')" height="100%" width="100%" />
					</div>
				</div>
				<div class="item">
					<div class="item-main">
						<div class="name">
							<span>{{$t('sycm.home.refundNumber')}}</span>
						</div>
						<div class="number">{{refundNumber.today_num}}</div>
					</div>
					<ul class="item-box">
						<li>
							<div class="cell-label">{{$t('sycm.home.dayBefore')}}</div>
							<div class="cell-value">{{refundNumber.yesterday_num || '-'}}</div>
						</li>
						<li>
							<div class="cell-label">{{$t('sycm.home.weekBefore')}}</div>
							<div class="cell-value">{{refundNumber.last_week_day_num || '-'}}</div>
						</li>
					</ul>
					<div class="item-chart">
						<area-chart id="refund-sum" v-if="refundNumber.list" :series-data="refundNumber.list" :series-name="$t('sycm.home.refundNumber')" height="100%" width="100%" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import payChart from '@/components/echart/payChart'
import areaChart from '@/components/echart/areaChart'
import { mapGetters } from 'vuex'
import { getInfo } from '@/api/seller/index'
import { fetchHomeStatistics, fetchPayMoney, fetchRefundRate, fetchRefundMoney, fetchRefundNumber } from "@/api/sycm"
export default {
	components: { payChart, areaChart },
	computed: {
		...mapGetters([
			'userInfo'
		])
	},
	data() {
		return {
			storeInfo: {},
			statistics: {},
			payData: {},
			refundRate: {},
			refundMoney: {},
			refundNumber: {}
		}
	},
	created() {
		this.getData();
	},
	methods: {
		imageLoadError(e) {
			e.target.src = this.$imageErrorLogo;
			e.target.style.objectFit = 'fill';
		},
		async getData() {
			const loading = this.$loading()
			try {
				const storeInfo = await getInfo({ uuid: this.$store.getters.uuid })
				this.storeInfo = storeInfo.data
				
				const payData = await fetchPayMoney({ uuid: this.$store.getters.uuid })
				this.payData = payData.data

				const statistics = await fetchHomeStatistics({ uuid: this.$store.getters.uuid })
				this.statistics = statistics.data
				
				const refundRate = await fetchRefundRate({ uuid: this.$store.getters.uuid })
				this.refundRate = refundRate.data

				const refundMoney = await fetchRefundMoney({ uuid: this.$store.getters.uuid })
				this.refundMoney = refundMoney.data

				const refundNumber = await fetchRefundNumber({ uuid: this.$store.getters.uuid })
				this.refundNumber = refundNumber.data

			} catch (error) {
				console.log(error)
			}
			loading.close()
		},
		goShop() {
			if (this.storeInfo.store_id > 0) {
				window.open(this.$itemBasePath + '/m/store/info/' + this.storeInfo.store_id);
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.sycm-page {
	.page-section {
		margin-bottom: 10px;
		.section-head {
			padding: 0 30px;
			line-height: 50px;
			background-color: #ffffff;
			border-bottom: 1px solid #e6e6e6;
			.title {
				font-size: 16px;
				font-weight: 400;
				display: flex;
				align-items: center;
				color: #333333;
				.svg-icon {
					font-size: 22px;
					margin-right: 6px;
				}
			}
		}
	}
	.page-survey {
		display: flex;

		.survey-main {
			flex: 1;
			width: 0;
			background-color: #ffffff;

			.survey-top {
				display: flex;
				padding: 0 30px;
				line-height: 50px;
				align-items: center;
				justify-content: space-between;

				.name {
					font-weight: 400;
					font-size: 16px;
					color: #333333;
				}

				.barbox {
					font-size: 12px;

					.time {
						color: #999;
					}

					.next {
						color: $--sycm-primary;
						margin-left: 20px;

						&:hover {
							color: $--sycm-hover-1;
						}
					}
				}
			}
			.survey-layout {
				display: flex;
				padding: 0 30px 30px 30px;
				.layout-left {
					flex-shrink: 1;
					width: 50%;
					box-sizing: border-box;
					padding-right: 15px;
					.survey-pay {
						display: flex;
						padding-top: 10px;
						justify-content: space-between;
					}
					.survey-chart {
						height: 200px;
					}
				}
				.layout-right {
					flex-shrink: 1;
					width: 50%;
					box-sizing: border-box;
					padding-left: 14px;
					li {
						padding: 4%;
						width: 50%;
						box-sizing: border-box;
						margin: 10px 0 16px;
						float: left;
						.survey-item {
							.number {
								font-size: 18px;
							}
						}
						.survey-data {
							overflow: hidden;
							p {
								width: 50%;
								float: left;
								span {
									margin-left: 6px;
								}
								&.rank {
									text-align: right;
								}
							}
						}
					}
				}
				.survey-item {
					display: flex;
					.item-icon {
						width: 48px;
						height: 48px;
						line-height: 48px;
						text-align: center;
						border-radius: 50%;
						font-size: 34px;
						margin-right: 12px;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #ffffff;
						&.payment-icon {
							background-color: $--sycm-primary;
						}
						&.visitor-icon {
							background-color: #fe7c24;
						}
						&.buyers-icon {
							background-color: #00ba26;
						}
						&.views-icon {
							background-color: $--sycm-yellow;
						}
						&.suborder-icon {
							background-color: #00b3fe;
						}
					}
					.item-main {
						.name {
							font-size: 14px;
							line-height: 20px;
						}
						.number {
							font-size: 24px;
							font-weight: 700;
							line-height: 32px;
							white-space: nowrap
						}
					}
				}
				.survey-data {
					p {
						color: #999999;
						line-height: 20px;
						white-space: nowrap;
						span {
							color: #666666;
							margin-left: 12px;
						}
					}
				}
			}
		}

		.survey-aside {
			width: 30%;
			padding: 30px 26px 34px;
			margin-left: 10px;
			box-sizing: border-box;
			background-color: #ffffff;
			box-shadow: 0 1px 4px rgb(0 0 0 / 10%);

			.aside-user {
				display: flex;

				.pic {
					flex-shrink: 0;
					width: 60px;
					height: 60px;
					border-radius: 50%;
					overflow: hidden;
					margin-right: 12px;
					box-shadow: 0 2px 4px 0 rgba(51, 187, 241, .15);
				}

				.name {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					font-size: 22px;
					color: #111111;
				}
			}

			.aside-action {
				text-align: center;
				margin: 15px 0 24px;

				.el-icon-s-shop {
					font-size: 14px;
				}
			}

			.aside-main {
				.item {
					margin-bottom: 15px;
					display: flex;
					width: 100%;
					align-items: center;

					.item-label {
						flex-shrink: 0;
						font-size: 14px;
						color: #111111;
					}

					.item-main {
						flex: 1;
						display: flex;
						text-align: right;
						justify-content: flex-end;

						.item-cell {
							flex: 1;
							display: flex;
							justify-content: flex-end;

							.item-cell-title {
								font-size: 12px;
								color: rgba(51, 51, 51, .6);
								margin-right: 4px;
							}

							.item-cell-value {
								font-size: 16px;
								margin-right: 4px;
								color: #111111;
							}

							.item-cell-text {
								font-size: 14px;
								color: #999999;
							}
						}

						.el-tag {
							border-radius: 12px;
						}
					}

					.item-text {
						color: #999999;
						font-size: 12px;
						color: rgba(51, 51, 51, .6);
					}
				}
			}
		}
	}
	.page-refund {
		padding: 30px;
		display: flex;
		background-color: #ffffff;
		.item {
			flex: 1;
			.item-main {
				padding: 0 30px;
				padding-right: 30%;
				line-height: 1.5;
				.name {
					font-size: 14px;
					color: #333333;
					span {
						margin-right: 10px;
					}
					.el-popover__reference {
						color: #999999;
						cursor: pointer;
					}
				}
				.number {
					font-size: 22px;
					font-weight: 700;
					margin-bottom: 11px;
				}
			}
			.item-box {
				padding: 0 30px;
				padding-right: 30%;
				li {
					display: flex;
					line-height: 1.5;
					color: #999999;
					font-size: 12px;
					justify-content: space-between;
					.cell-value {
						span {
							margin-left: 10px;
						}
					}
				}
			}
			.item-chart {
				height: 300px;
			}
		}
	}
}
</style>